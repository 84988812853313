.sh-labor-page {
    font-family: usual, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;

    &-container {
        max-width: 1903px;
    }

    &-row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-left: -20px;
        justify-content: center;
    }

    &-col-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        padding: 20px;
    }

    &-col-4 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 20px;

        @media (min-width: 768px) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media (min-width: 992px) {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
    }

    &-col-6 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 20px;

        @media (min-width: 992px) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &-col-8 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 20px;

        @media (min-width: 992px) {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
    }

    &-order-0 {
        @media (min-width: 992px) {
            order: 0;
        }

    }

    &-order-1 {
        @media (min-width: 992px) {
            order: 1;
        }
    }

    &__header {
        background-color: $c-white;
        text-align: center;
        padding-top: 144px;
        padding-bottom: 65px;

        &-tagline {
            font-size: 18px;
            line-height: 1.55;
            font-weight: bold;
            color: $c-brand;
            margin-bottom: 20px;
        }

        &-title {
            max-width: 705px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 33px;

            @media (min-width: 1200px) {
                font-size: 56px;
            }
            @media (max-width: 575px) {
                font-size: 36px;
            }
        }

        &-description {
            max-width: 705px;
            margin-left: auto;
            margin-right: auto;
            font-size: 18px;
        }
    }

    &__principles {
        $p: &;

        padding-top: 15px;
        padding-bottom: 70px;
        background-color: $brand-light;
        position: relative;

        @media (min-width: 768px) {
            padding-top: 70px;
            padding-bottom: 115px;
        }

        &-icon-rt {
            position: absolute;
            z-index: 1;
            width: 49.58333vw;
            max-width: 952px;
            min-width: 483px;
            right: -156px;
            top: 398px;

            @media (min-width: 576px) {
                width: get-vw(952);
                min-width: 345px;
                right: -113px;
                top: 385px;
            }
            @media (min-width: 768px) {
                top: 270px;
            }

            @media (min-width: 992px) {
                right: 0;
                top: get-vw(-180);
            }
        }

        &-icon-lb {
            position: absolute;
            z-index: 1;
            width: get-vw(644);
            max-width: 325px;
            min-width: 268px;
            left: -50px;
            bottom: 477px;

            @media (min-width: 768px) {
                max-width: 644px;
                min-width: 300px;
                left: -47px;
                bottom: 300px;
            }
            @media (min-width: 768px) {
                left: 0;
                bottom: 0;
            }
        }

        .container {
            position: relative;
            z-index: 9;
            padding-left: 20px;
            padding-right: 20px;
        }

        &-tagline {
            font-size: 18px;
            line-height: 1.55;
            font-weight: bold;
            color: $c-brand;
            margin-bottom: 20px;
        }

        &-title {
            margin-bottom: 33px;
            font-size: 36px;

            @media (min-width: 576px) {
                font-size: 40px;
            }
            @media (min-width: 768px) {
                font-size: 46px;
            }
            @media (min-width: 992px) {
                font-size: 52px;
            }
            @media (min-width: 1200px) {
                font-size: 56px;
            }
            @media (max-width: 575px) {
                font-size: 36px;
            }

            span {
                position: relative;
                display: inline-block;
                z-index: 3;

                &:before {
                    content: '';
                    position: absolute;
                    width: calc(100% + 10px);
                    height: 22px;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    background-color: #66d4d6;
                    z-index: -1;
                }
            }
        }

        &-description {
            font-size: 18px;
        }

        &-item {
            padding: 34px 34px 80px;
            background-color: $c-white;
            box-shadow: 0 30px 60px rgba($heading-color, .5);

            @media (min-width: 768px) {
                padding: 40px 40px 100px;
            }

            &.item-1 {
                @media (min-width: 992px) {
                    margin-top: 110px;
                }
            }

            &.item-2 {
                background-color: $heading-color;

                @media (min-width: 992px) {
                    margin-top: -55px;
                }

                #{$p}-item-title,
                #{$p}-desc {
                    color: $c-white;
                }
            }

            &.item-3 {
                background-color: $c-brand-a;

                @media (min-width: 992px) {
                    margin-top: -110px;
                }

                #{$p}-num {
                    color: $c-white;
                }
            }

            &.item-4 {
                background-color: $brand-light;
            }

            &.item-5 {
                background-color: $c-brand-a;

                #{$p}-num {
                    color: $c-white;
                }
            }

            &.item-6 {

                @media (min-width: 992px) {
                    margin-top: -110px;
                }
            }

            &.item-7 {
                background-color: $heading-color;

                @media (min-width: 992px) {
                    margin-top: -25px;
                }

                #{$p}-item-title,
                #{$p}-desc {
                    color: $c-white;
                }
            }
        }

        &-num {
            font-size: 14px;
            line-height: 1;
            color: $c-brand-a;
            margin-bottom: 20px;
        }

        &-item-title {
            font-size: 24px;
            font-weight: bold;
            color: $heading-color;
            margin-bottom: 20px;

            @media (min-width: 768px) {
                font-size: 32px;
            }
        }

        &-desc {
            font-size: 18px;
            line-height: 28px;
            color: $heading-color;
        }

        &-cta {
            max-width: 705px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 15px;
            text-align: center;

            &-title {
                font-size: 24px;
                line-height: 1;
                font-weight: bold;
                color: $heading-color;
                margin-bottom: 15px;

                @media (min-width: 768px) {
                    font-size: 36px;
                    margin-bottom: 25px;
                }
            }

            &-desc {
                font-size: 18px;
                line-height: 22px;
                color: $heading-color;
                margin-bottom: 25px;
            }

            &-btn {
                display: inline-block;
                background-color: $heading-color;
                border-radius: 6px;
                color: $c-white;
                font-size: 16px;
                line-height: 1;
                padding: 12px 28px;

                &:hover {
                    background-color: $brand-dark;
                }
            }
        }
    }

    &__impact {
        background-color: $brand-mid;
        padding-top: 60px;
        padding-bottom: 20px;

        @media (min-width: 768px) {
            padding-top: 75px;
            padding-bottom: 90px;
        }

        .container {
            position: relative;
            z-index: 9;
            padding-left: 20px;
            padding-right: 20px;
            max-width: 1117px;
        }

        .sh-labor-page-row {
            justify-content: center;
        }

        &-des {
            text-align: center;
            max-width: 705px;
            margin-left: auto;
            margin-right: auto;
        }

        &-desc {
            font-size: 18px;
            line-height: 1.55;
            margin-bottom: 20px;
            color: $heading-color;
        }

        &-item {
            position: relative;
            width: 332px;
            height: 332px;

            @media (max-width: 580px) {
                max-width: calc( 100% - 40px );
                margin: auto;
                max-height: 100%;
            }

            &.item-1 {

            }

            &-circle {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                animation: impact-circle 18s linear infinite;

                svg {
                    width: 100%;
                    height: 100%;
                    stroke-width: .33;
                    stroke-dasharray: .15, 3;
                    fill: transparent;
                    stroke: $heading-color;
                    stroke-linecap: round;
                }

                @keyframes impact-circle {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }

            &-content {
                position: absolute;
                z-index: 5;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                color: $heading-color;
                min-width: 80%;
            }

            &-icon {
                margin-bottom: 15px;
                margin-left: auto;
                margin-right: auto;

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &-title {
                font-size: 36px;
                line-height: 1;
                font-weight: 700;
                margin-bottom: 10px;

                @media (min-width: 576px) {
                    font-size: 42px;
                }
                @media (min-width: 768px) {
                    font-size: 50px;
                }
                @media (min-width: 992px) {
                    font-size: 56px;
                }
            }

            &-text {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    &__story {
        background-color: $heading-color;
        padding-top: 55px;
        padding-bottom: 80px;
        color: $c-white;

        @media (min-width: 992px) {
            padding-top: 155px;
            padding-bottom: 155px;
        }

        &-title {
            font-size: 24px;
            color: $brand-mid;
            font-weight: bold;
            margin-bottom: 35px;

            @media (min-width: 768px) {
                font-size: 36px;
            }
        }

        &-description {
            font-size: 18px;
            line-height: 28px;

            @media (min-width: 992px) {
                max-width: 540px;
            }

            p {
                margin-bottom: 1em;
            }
        }

        &-img-1 {
            box-shadow: 0 30px 60px rgba($heading-color, .5);
            border-radius: 8px;
            max-width: 100%;
            margin-bottom: 25px;

            @media (min-width: 992px) {
                margin-bottom: 70px;
            }
            @media (min-width: 1200px) {
                margin-left: 180px;
                width: get-vw(540);
                max-width: 540px;
                min-width: 360px;
            }

            @media (max-width: 640px) {
                max-width: 100%;
            }
        }

        &-img-2 {
            box-shadow: 0 30px 60px rgba($heading-color, .5);
            border-radius: 8px;
            max-width: 100%;

            @media (min-width: 1200px) {
                width: get-vw(540);
                max-width: 540px;
                min-width: 360px;
            }

            @media (max-width: 640px) {
                max-width: 100%;
            }
        }

        &-list-title {
            font-size: 36px;
            font-weight: bold;
            line-height: 1.2;
            margin-top: 50px;
            text-align: center;
            margin-bottom: 10px;

            @media (min-width: 768px) {
                font-size: 56px;
                margin-bottom: 20px;
            }

            @media (min-width: 992px) {
                margin-top: get-vw(160);
            }
        }

        &-list-text {
            font-size: 18px;
            line-height: 28px;
            color: $brand-mid;
            text-align: center;
            margin-bottom: 50px;

            @media (min-width: 992px) {
                margin-bottom: get-vw(130);
            }
        }

        &-img {
            width: 100%;
            max-width: 705px;
            box-shadow: 0 30px 60px rgba(21, 51, 67, 0.5);
            border-radius: 8px;
        }

        &-item {
            position: relative;
            padding-left: 27px;
            display: flex;
            align-items: center;
            padding-bottom: 20px;

            &:first-child {
                &:before {
                    background-color: $c-white;
                }

                &:after {
                    @media (max-width: 575px) {
                        height: auto;
                        bottom: 0;
                        top: calc(50% - 10px);
                    }
                }
            }

            &:last-child {
                &:before {
                    background-color: transparent;
                    border: 2px solid $brand-mid;
                }

                &:after {
                    @media (min-width: 576px) {
                        display: none;
                    }

                    @media (max-width: 575px) {
                        height: auto;
                        top: 0;
                        bottom: calc(50% + 14px);
                    }
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: calc(50% - 10px);
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background-color: $brand-mid;
                border-radius: 50%;
                z-index: 3;
            }

            &:after {
                content: '';
                position: absolute;
                left: 6px;
                bottom: 4px;
                width: 1px;
                height: calc(100% - 28px);
                background-color: $brand-mid;
                z-index: 1;

                @media (max-width: 575px) {
                    height: 100%;
                    bottom: 0;
                }
            }

            &-date {
                text-transform: uppercase;
                font-size: 14px;
                color: rgba($c-white, .5);
                white-space: nowrap;
            }

            &-sep {
                font-size: 14px;
                color: rgba($c-white, .5);
                padding: 0 15px;
            }

            &-text {
                font-size: 16px;
                line-height: 1.2;

                a {
                    text-decoration: underline;
                    color: $brand-mid;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.sh-join {
    background-color: $brand-light;
    background-repeat: no-repeat;
    background-position: 50% 110%;
    background-size: contain;
    background-image: url(../img/peas_1.png);
    color: $heading-color;
    padding-top: 50px;
    padding-bottom: 316px;

    @media (min-width: 768px) {
        padding-top: get-vw(140);
        padding-bottom: get-vw(150);
        background-size: 350px;
        background-position: 0 50%;
    }
    @media (min-width: 1200px) {
        background-size: 400px;
    }
    @media (min-width: 1400px) {
        background-position: 0 50%;
        background-size: auto;
    }
    @media (min-width: 1500px) {
        background-position: get-vw(40) 50%;
    }
    @media (min-width: 1600px) {
        background-position: get-vw(70) 50%;
    }
    @media (min-width: 1700px) {
        background-position: get-vw(100) 50%;
    }
    @media (min-width: 1800px) {
        background-position: get-vw(130) 50%;
    }

    @media (min-width: 576px) and (max-width: 767px) and (orientation: landscape) {
        background-position: 50% 126%;
        background-size: 390px;
    }

    .sh-labor-page-row {
        justify-content: flex-end;
    }

    .container {
        position: relative;
        z-index: 8;
        padding-left: 20px;
        padding-right: 20px;
    }

    &-col {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            font-size: 36px;
        }
    }

    &__description {
        font-size: 18px;
        line-height: 28px;
        max-width: 705px;
        margin-bottom: 30px;
    }
}
