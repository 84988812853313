.four-o-four {
    margin-top: 50px;
    height: 58vh;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
