.form {
  width: 100%;
  max-width: 455px;

  form {
    position: relative;
    width: 100%;
  }

  input[type='email'], input[type='tel'], input[type='text'] {
    margin-bottom: 35px;
    padding: 16px 20px;
    width: 100%;
    font-size: 1.6rem;
    font-family: $text-font-stack;
    background-color: $light-grey;
    border: none;
    outline: none;
    border-radius: 26px;

    @include respond-to(sm) {
      padding-right: 150px;
      margin-bottom: 0;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

	
  .btn {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: 55px;

    @include respond-to(sm) {
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
      width: auto;
      height: auto;
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  .wpcf7-not-valid-tip {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 1.4rem;
    color: red;
    display: none;
  }

  .wpcf7-response-output {
    padding-top: 35px;
    text-align: center;
  }

  .wpcf7-validation-errors {
    font-size: 1.4rem;
    color: red;
  }
}

.wpcf7-form-control-wrap{
  width: 100%;
}
