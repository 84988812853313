body.page-template-template-careers {
   .join-us {
      position: relative;
      overflow: hidden;
      width: 100%;

      @include respond-to(sm) {}

      &__inner {
         align-items: center;
         display: flex;
         width: 100%;
         max-width: 1900px;
         padding: 130px 40px 0px 40px;
         margin: 0 auto;
         min-height: 700px;
         
         @include for-size(desktop) {
            flex-direction: column-reverse;
            padding: 100px 0 0 0;
         }
      }
      
      &__text-wrap {
         position: relative;
         width: 730px;
         z-index: 1;

         @include for-size(tablet) {
            width: 100%;
            padding: 0;
            text-align: center;
         }

         .content {
            position: relative;
            padding: 0 40px;
            z-index: 2;

            @include for-size(desktop) {
               margin-bottom: 20px;
            }
            
            .preheader {
               color: $c-brand;
               font-size: 18px;
               font-weight: 700;
               line-height: 160%;
            }
            
            h1 {
               font-size: 56px;
               line-height: 125%;
               margin: 27px 0 40px 0;
               
               @include for-size(tablet) {
                  margin: 20px 0 30px 0;
                  font-size: 40px;
                  line-height: 115%;
               }

               u {
                  padding: 0;
                  box-shadow: inset 0 -20px 0 0 $c-brand;
                  text-decoration: none;
               }
            }

            .btn.btn--dark_bg {
               &:hover {
                  background-color: $c-brand;
               }
            }
         }

         .big-white-circle {
            background-color: #fff;
            position: absolute;
            border-radius: 50%;
            bottom: -100px;
            height: 110vw;
            left: -84px;
            top: 50%;
            transform: translateY(-50%);
            width: 110vw;
            z-index: 1;

            @include for-size(desktop) {
               left: 50%;
               top: 50%;
               transform: translate(-50%, -70%) scale(1.4);
            }

            @include for-size(phone) {
               transform: translate(-50%, -70%) scale(1.8);
            }
         }
      }
      
      &__img-wrap {
         position: relative;
         z-index: 2;
         align-self: end;
         flex: 1;

         img {
            width: 100%;
            max-width: 100%;
            min-width: 800px;

            @include for-size(desktop) {
               min-width: 100%;
            }
         }
      }
   }

   .meaningful_impact {
      background-color: #153343;
      overflow: hidden;
      padding-bottom: 40px;
      padding-top: 40px;
      position: relative;
      text-align: center;

      @include for-size(tablet) {
         padding-bottom: 60px;
      }

      &__inner {
         display: flex;
         position: relative;
         max-width: 1820px;
         //padding: 0;
         z-index: 2;

         @include for-size(tablet) {
            flex-direction: column;
         }
      
         .embed-container-wrapper {
            flex: 0 0 auto;
            width: math.div(1077, 1903) * 100%;

            @include for-size(tablet) {
               width: 100%;
               margin-bottom: 40px;
            }
 
            .embed-container { 
               height: auto;
               max-width: 100%;
               overflow: hidden;
               padding-bottom: 56.25%;
               position: relative; 

               iframe,
               object,
               embed { 
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
               }
            }
         } 
      }

      &__text-wrap {
         display: flex;
         flex-direction: column;
         justify-content: center;
         max-width: 780px;
         margin: 0 auto;
         padding: 0 20px;
         padding-left: 40px;

         @include for-size(tablet) {
            padding-left: 20px;
         }

         @include for-size(phone) {
            padding: 0;
         }
      
         h1 {
            color: #fff;
            margin-bottom: 60px;
            text-align: left;

            @include for-size(tablet) {
               margin-bottom: 40px;
               text-align: center;
            }
         }

         p {
            color: #fff;
            line-height: 160%;
            text-align: left;

            &:not(:last-of-type) {
               margin-bottom: 40px;
            }
         }
      }
   }

   .working_here {
      background-color: #EBF6F4;
      overflow: hidden;
      padding-bottom: 70px;
      padding-top: 70px;
      position: relative;
      text-align: center;

      @include respond-to(sm) {
      padding: 90px 0;
      }

      &__inner {
         position: relative;
         z-index: 2;

         h1 {
            margin-bottom: 70px;
            
            @include for-size(tablet) {
               margin-bottom: 24px;
            }
         }

         .reasons {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            margin-right: -20px;
            margin-left: -20px;
            margin-bottom: 100px;

            @include for-size(desktop) {
               margin-bottom: 50px;
            }
            
            @include for-size(phone) {
               margin-bottom: 50px;
            }

            .reason {
               padding: 20px;
               position: relative;
               flex: 0 0 33%;
               max-width: 33%;

               @include for-size(tablet) {
                  flex: 0 0 50%;
                  margin-top: 0 !important;
                  max-width: 50%;
               }
               
               @include for-size(phone) {
                  flex: 0 0 100%;
                  max-width: 100%;
               }

               &:nth-of-type(2) {
               margin-top: 180px;
               };

               &:nth-of-type(3) {
               margin-top: 60px;
               };

               &:nth-of-type(4) {
               margin-top: -180px;
               };

               &:nth-of-type(6) {
               margin-top: -110px;
               };

               &:nth-of-type(7) {
               margin-top: -250px;
               };

               &:hover,
               &:active {
                  .content {
                     .bg-img {
                        opacity: 1;
                     }
                  }
               }

               &.active {
                  .content {
                     .bg-img {
                        opacity: 1;
                     }
                  }
               }
            
               .content {
                  background-color: #fff;
                  box-shadow: 0 30px 60px rgb(21 51 67 / 50%);
                  cursor: pointer;
                  padding: 40px 40px 100px;
                  text-align: left;
                  position: relative;
                  
                  .heading {
                     color: $heading-color;
                     font-size: 32px;
                     font-weight: 700;
                     line-height: 100%;
                     margin-bottom: 20px;
                     transition: 0.4s;

                     @include for-size('tablet') {
                        font-size: 24px;
                     }
                  }
                  
                  p {
                     transition: 0.3s;
                  }

                  .bg-img {
                     position: absolute;
                     top: 0;
                     left: 0;
                     background-repeat: no-repeat;
                     background-position: center;
                     background-size: cover;
                     width: 100%;
                     height: 100%;
                     opacity: 0;
                     transition: 0.3s;
                  }
               }
            }
         }

         .quotes-wrapper {
            overflow: hidden;
            height: 500px;
            position: relative;
            
            @include for-size(tablet) {
               height: 750px;
            }
            
            .quotes-list {
               height: 100%;
               position: relative;
               
               .quotes-list-item {
                  display: flex;
                  gap: 40px;
                  left: 0;
                  position: absolute;
                  text-align: left;
                  top: 0;
                  opacity: 0;
                  transition: .3s;

                  @include for-size(tablet) {
                     flex-direction: column;
                     gap: 0;
                  }
                  
                  &.is-selected {
                     opacity: 1;
                  }
                  
                  .img {
                     background-position: center;
                     background-repeat: no-repeat;
                     background-size: cover;
                     border-radius: 50%;
                     height: 330px;
                     width: 330px;
                     
                     @include for-size(tablet) {
                        align-self: center;
                     }
                  }

                  .quote {
                     flex: 1;
                     margin-top: 50px;

                     @include for-size(tablet) {
                        margin-top: 20px;
                     }
                     
                     p {
                        font-size: 24px;
                        line-height: 160%;
                        margin-bottom: 44px;
                        
                        u {
                           box-shadow: inset 0 -10px 0 0 $c-brand;
                           text-decoration: none;
                        }
                     
                        @include for-size(tablet) {
                           font-size: 18px;
                           line-height: 125%;

                           u {
                              box-shadow: inset 0 -8px 0 0 $c-brand;
                              margin-bottom: 30px;
                           }
                        }
                     }
                     
                     .quotee {
                        font-weight: 700;
                        font-size: 18px;
                     }
                     
                     .quotee-role {
                        font-size: 18px;
                     }
                  }
               }
            }

            .quotes-list-dots {
               align-items: center;
               bottom: 0;
               display: flex;
               justify-content: center;
               left: 0;
               position: absolute;
               width: 100%;
            
               .dot {
                  background-color: $text-color;
                  border-radius: 100%;
                  cursor: pointer;
                  height: 8px;
                  margin-left: 3px;
                  margin-right: 3px;
                  opacity: 0.2;
                  width: 8px;
                  
                  &.is-active {
                     opacity: 1;
                  }
               }
            }
         }
      }
   }

   .team_highlight {
      background-color: #153343;
      overflow: hidden;
      padding-bottom: 70px;
      padding-top: 70px;
      position: relative;
      text-align: center;

      @include respond-to(sm) {
         padding: 190px 0;
      }

      &__inner {
         max-width: 780px;
         position: relative;
         z-index: 3;

         h2 {
         color: #fff;
         }

         p {
         color: #fff;
         margin: 38px 0;
         line-height: 160%;
         }

         .btn {
            color: $heading-color;
            background: $c-brand;

            &:hover {
               color: $c-white;
               background: $heading-color;
            }
         }
      }

      .gradient {
         background: linear-gradient(90deg, rgba(21, 51, 67, 0) 0%, rgba(21, 51, 67, 0.85) 39.06%, rgba(21, 51, 67, 0.85) 80.21%, rgba(21, 51, 67, 0) 100%);
         height: 100%;
         left: 0;
         position: absolute;
         top: 0;
         width: 100%;
         z-index: 2;
      }

      #waves-animation {
         // animation: animatedBackground 10s linear infinite;
         background: url(../img/career-waves.png) 0 0 repeat-x;
         height: 100%;
         left: 0;
         position: absolute;
         top: 0;
         width: 100%;
         z-index: 1;
      }

   }
   
   .remodal.video-modal {
      .video-modal-content {
         background-color: $c-white;
         border-radius: 6px;
         padding: 70px 20px 10px 20px;

         .embed-container { 
            height: auto;
            max-width: 100%;
            overflow: hidden;
            padding-bottom: 56.25%;
            position: relative; 
            margin-bottom: 40px;

            iframe,
            object,
            embed { 
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
            }
         }
      }
   }

   .our_trophy_shelf {
      background-color: $brand-mid;
      overflow: hidden;
      padding-top: 90px;
      
      @include for-size(tablet) {
         padding-top: 50px;
      }

      &__inner {
         display: flex;
         
         @include for-size(tablet) {
            flex-direction: column-reverse;
         }
      }

      &__img-wrap {
         align-self: end;
         bottom: 0;
         flex: 1;
         left: 0;
         justify-self: start;
         
         @include for-size(tablet) {
            align-self: initial;
            max-width: 100%;
         }
      }
      
      &__text-wrap {
         align-self: center;
         flex: 1;
         padding: 0 20px 40px 0;
         margin-left: -8%;
         
         @include for-size(tablet) {
            margin-left: 0;
            padding: 0 20px 20px 20px;
         }

         h1 {
            margin-bottom: 40px;
            text-align-last: left;

         }

         .trophies-list {
            display: inline-block;
            text-align: left;

            .trophies-list-item {
               font-size: 16px;
               padding: 15px 60px 15px 0;

               &:not(:last-child) {
                  border-bottom: 1px solid #56B4B9;
               }

               p {
                  color: #153343;
                  
                  a {
                     color: #153343;
                     font-weight: 700;
                     transition: .3s;

                     &:hover {
                        color: #fff;
                     }
                  }
               }
            }
         }
      }
   }

   .our_benefits {
      background-color: #EBF6F4;

      h1 {
         margin-bottom: 50px;

         @include for-size(tablet) {
            margin-bottom: 35px;
            margin-top: 70px;
         }
      }

      &__inner {
         padding-top: 6rem;
         padding-bottom: 6rem;

      &.container {
         max-width: 1820px;
      }

      @include respond-to(md) {
         display: flex;
         gap: 40px;
         align-items: center;
      }
      }

      &__img-wrap {
         display: flex;
         flex-wrap: wrap;
         
         @include for-size(tablet) {
            margin-bottom: 20px;
         }

         img {
            max-width: 705px;
            width: 100%;
         }
      }

      &__text-wrap {
         .benefit-list-item {
            text-align: left;
            display: flex;
            gap: 10px;
            justify-content: space-between;
            margin-bottom: 25px;
            flex-direction: column;

            @include respond-to(sm) {
               flex-direction: row;
               gap: 40px;
               margin-bottom: 45px;
            }

            .heading {
               width: 330px;

               &__index {
                  color: $c-gray;
                  line-height: 160%;
                  position: relative;

                  &:after {
                     background-color: $c-gray;
                     content: '';
                     height: 1px;
                     left: 35px;
                     position: absolute;
                     top: 14px;
                     width: 90%;
                  }
               }

               &__text {
                  color: $heading-color;
                  font-size: 32px;
                  font-weight: 700;
                  line-height: 120%;
                  
                  @include for-size(tablet) {
                     font-size: 24px;
                  }
               }
            }

            .description {
               flex: 1;
               max-width: 550px;
            }
         }
      }
   }

   .join-our-team {
      background-color: $brand-mid !important;

      @include for-size(tablet) {
         padding-bottom: 50px;
         background-image: none;
      }

      .sh-about-page-row {
         margin-left: 42%;

         @include for-size(tablet) {
            margin-left: 0;
         }

         .sh-join-col {
            @include for-size(phone) {
               padding-left: 0;
            }
         }
      }
   }

   .remodal-wrapper {
      max-width: 1440px;
      margin: 0 auto;
   }
}

@keyframes animatedBackground {
   from {
     background-position: 0 0;
   }

   to {
     background-position: 100% 0;
   }
}