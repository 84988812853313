// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &:hover {
        background-color: #fff;
    }

    &.green {
        &:hover {
            background-color: $brand-mid;
        }

        &.is-opened,
        &.is-scrolling {
            background-color: $brand-mid;
        }

        .sub-menu,
        .sheld-header__menu-submenu {
            background-color: $brand-mid !important;
        }

        .page-header__menu li .sub-menu li:before {
            background-image: url(../img/menu-nd-level-green.svg);
        }

        .page-header__resource {
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.5) 0%,
                rgba(255, 255, 255, 0) 100%
            );
        }
        .page-header__btns .js-show-demo:hover {
            background-color: $c-white;
            color: $heading-color;
        }
    }

    @include transition;

    &.is-opened,
    &.is-scrolling {
        background-color: #fff;
    }

    &__resource {
        background: linear-gradient(
            180deg,
            #66d4d6 0%,
            rgba(102, 212, 214, 0) 100%
        );
        border-radius: 6px;
        overflow: hidden;
        padding: 20px !important;
        display: flex !important;
        flex-direction: row;
        align-items: flex-start;
        transition: all 0.25s ease;

        &:hover {
            transform: scale(1.01);
        }

        &:after {
            display: none;
        }

        img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 50%;
            display: block;
            margin-right: 20px;
        }

        h6 {
            letter-spacing: 0.05em;
            font-size: 12px;
            text-transform: uppercase;
            color: $heading-color;
            opacity: 0.5;
            line-height: 1;
            display: block;
            padding-bottom: 10px;
            font-weight: bold;
        }

        h5 {
            color: $heading-color;
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
            display: block;
            padding-bottom: 10px;
        }

        p {
            font-size: 14px !important;
            color: $heading-color;
            line-height: 16.72px !important;
            opacity: 0.5;
            margin-bottom: 0;
            padding-bottom: 0 !important;
        }
    }

    &__inner {
        max-width: $max-width;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        position: relative;
        z-index: 3;
        width: 188px;
        @include transition;

        &:hover {
            opacity: 0.7;
        }
    }
    &__nav--wrapper {
        width: calc(100% - 190px);
    }
    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    &__menu {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        &--desktop {
            @media (max-width: 991px) {
                display: none !important;
            }
        }
        &--mobile {
            @media (min-width: 992px) {
                display: none !important;
            }
        }

        > li {
            padding: 23px 0;

            &:hover {
                > a {
                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        li {
            margin-right: 40px;

            &:last-child {
                margin-right: 0;
            }

            &.menu-item-has-children {
                > a {
                    padding-right: 20px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 7px;
                        width: 12px;
                        height: 8px;
                        background-image: url(../img/menu-top-arrow.svg);
                        background-size: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        transition: all 0.25s ease;
                    }
                }
            }

            &.current-menu-item {
                a {
                    font-weight: 600;
                }
            }

            .style {
                &-two-columns {
                    > .sub-menu {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        position: static;
                        left: 0;

                        > li {
                            width: 50%;
                            display: block;
                            margin: 0;
                            padding-bottom: 8px;
                        }
                    }
                }

                &-one-column {
                    > .sub-menu {
                        position: static;
                        left: 0;

                        > li {
                            display: block;
                            padding-bottom: 8px;
                        }
                    }
                }
            }

            &.type {
                &-basic {
                    position: relative;

                    .sub-menu {
                        opacity: 0;
                        visibility: hidden;
                    }
                    &.active,
                    &:hover {
                        .sub-menu {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }

                &-mega-menu {
                    position: static;
                    &.active,
                    &:hover {
                        > .sheld-header__menu-submenu {
                            // visibility: visible;
                            // opacity: 1;
                            // z-index: 1;
                            display: block;
                        }
                    }

                    .sheld-header__menu-submenu {
                        width: 100vw;
                        background-color: #fff;
                        // visibility: hidden;
                        // opacity: 0;
                        display: none;
                        position: absolute;
                        left: 0;
                        top: 100%;
                        margin: 0 calc(-50vw + 50%);
                        // transition: opacity 0.25s ease, visibility 0.25s ease;
                        box-shadow: 0px 7px 8px 0px rgba(34, 60, 80, 0.2);

                        &-items {
                            max-width: $max-width;
                            margin: 0 auto;
                            z-index: 1;
                            width: 100%;
                            padding: 40px 20px 75px;

                            > .sub-menu {
                                margin: 0 -20px;
                                display: flex;
                                left: 0;
                                flex-direction: row;
                                align-items: flex-start;

                                > li {
                                    width: 33.3334%;
                                    padding: 0 20px;
                                    margin: 0;

                                    &:before {
                                        display: none;
                                    }

                                    > a {
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 1;
                                        padding-bottom: 15px;
                                        display: inline-block;
                                        padding-right: 0;
                                        @include underline;

                                        &:after {
                                            bottom: 11px;
                                        }

                                        &:before {
                                            display: none;
                                        }
                                    }

                                    p {
                                        font-size: 16px;
                                        line-height: 19px;
                                        font-weight: 400;
                                        opacity: 0.5;
                                        color: $heading-color;
                                        display: block;
                                        padding-bottom: 32px;
                                    }
                                }
                            }
                        }
                    }

                    .sub-menu {
                        position: relative;
                        padding: 0;
                        box-shadow: none;
                    }
                }
            }

            .sub-menu {
                position: absolute;
                transition: all 0.25s ease;
                display: block;
                top: calc(100% - 17px);
                min-width: 200px;
                background-color: #fff;
                padding: 10px 20px;
                box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
                left: -20px;

                li {
                    text-align: left;
                    display: block;

                    &:before {
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 12px;
                        background-image: url(../img/menu-nd-level.svg);
                        background-size: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin-right: 15px;
                    }
                }
            }
        }

        a {
            color: $heading-color;
            font-size: 16px;
            font-weight: 400;
            @include underline;
            line-height: 19.1px;

            &::after {
                bottom: -5px;
            }
        }

        @include respond-to(sm) {
            display: flex;

            ul {
                display: flex;
                align-items: center;
            }
        }
    }

    &__btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;

        @include respond-to(sm) {
            flex-direction: row;
        }

        li {
            display: flex;
            justify-content: center;
            align-items: center;

            @include respond-to(sm) {
                margin-bottom: 0;
                margin-right: 10px;
            }

            @include respond-to(md) {
                margin-right: 0;
            }
        }

        .btn {
            border-radius: 6px;
            font-weight: 600;
            font-size: 16px;
            line-height: 1;
            padding-top: 13px;
            padding-bottom: 13px;

            &--secondary {
                border: none;
                font-weight: 400;
                padding: 0;
                margin-right: 25px;
                position: relative;

                &:hover {
                    background-color: transparent;
                    color: $heading-color;

                    &:after {
                        transform: scaleX(1);
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -5px;
                    width: 100%;
                    height: 2px;
                    background-color: $heading-color;
                    transition: all 0.3s ease-in-out;
                    transform: scaleX(0);
                    transform-origin: left;
                }
            }
        }
    }

    @media (max-width: 992px) {
        padding: 11px 0;
        &__nav--wrapper {
            position: absolute;
            top: 100%;
            right: -100%;
            max-width: 460px;
            width: 100%;
            height: calc(100vh - 60px);
            max-height: calc(100vh - 60px);
            overflow-y: auto;
            transition: all 0.25s ease;
        }
        &__nav {
            width: 100%;
            flex-direction: column;
            background-color: #fff;
            padding: 20px;
            justify-content: flex-start;
            align-items: flex-start;
            min-height: calc(100vh - 60px);
        }

        &.green {
            .page-header__nav {
                background-color: $brand-mid;
            }
        }

        @include when-inside('.page-header.is-opened') {
            &__nav--wrapper {
                right: 0;
            }
        }

        &__resource {
            display: none !important;
        }

        &__menu {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-bottom: 20px;

            > li {
                border-bottom: 1px solid rgba($heading-color, 0.25);
                display: block;
                width: 100%;
                margin: 0;
                padding: 20px 0 20px 0;

                &:hover {
                    > a {
                        &:before {
                            transform: rotate(0deg);
                        }
                    }
                }

                &:first-child {
                    padding-top: 0;
                }

                > a {
                    font-weight: 700;
                    line-height: 1;

                    &:after {
                        display: none;
                    }
                }

                &.type-mega-menu {
                    .sheld-header__menu-submenu {
                        visibility: visible;
                        opacity: 1;
                        position: static;
                        margin: 0;
                        width: 100%;
                        box-shadow: none;
                        padding: 0;

                        &-items {
                            padding: 0;
                        }

                        .sub-menu {
                            margin: 0;
                            flex-direction: column;

                            > li {
                                width: 100%;
                                padding: 0 0 20px 0;

                                &:last-child {
                                    padding-bottom: 0;
                                }

                                a {
                                    font-weight: normal;
                                    font-size: 18px;
                                    line-height: 28px;
                                    padding: 0;
                                }
                            }

                            .sub-menu {
                                display: none;
                            }
                        }
                    }
                }
            }

            a {
                font-size: 18px;
                line-height: 28px;

                &:after {
                    display: none !important;
                }
            }

            li {
                margin: 0;

                p {
                    display: none !important;
                }

                .sub-menu {
                    position: relative;
                    left: 0;
                    visibility: visible;
                    opacity: 1;
                    box-shadow: none;

                    max-height: 0;
                    overflow: hidden;
                    padding: 0;
                    transition: all 0.25s ease;

                    li {
                        padding-bottom: 20px;

                        &:last-child {
                            padding-bottom: 0;
                        }

                        &:before {
                            display: none;
                        }
                    }
                }

                &.active {
                    > a {
                        &:before {
                            transform: rotate(180deg);
                            top: 8px;
                        }
                    }

                    .sub-menu {
                        padding: 20px 0 0;
                        max-height: 800px;
                        transition: all 0.25s ease;
                    }
                }
            }
        }

        &__btns {
            flex-direction: column-reverse;
            width: 100%;

            li {
                width: 100%;
                margin-right: 0;
            }

            .btn {
                margin-bottom: 20px;
                text-align: center;
                margin-right: 0;
                display: block;
                width: 100%;
                max-width: 100%;
                border: 2px solid $heading-color;

                &.btn--secondary {
                    border-radius: 6px;
                    padding-top: 13px;
                    padding-bottom: 13px;
                    font-weight: 600;

                    &:hover {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            @media (max-width: 767px) {
                margin-bottom: 40px;
            }
        }

        &__logo {
            width: 168px;
        }
    }

    @media (max-width: 580px) {
        &__nav {
            max-width: 100%;
        }
    }
}
