.hamburger {
	position: relative;
	z-index: 50;
	width: 30px;
	height: 19px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	background-color: transparent;
	cursor: pointer;

	@media (min-width: 992px) {
		display: none;
	}

	span,
	&::before,
	&::after {
		position: absolute;
		left: 0;
		right: 0;
		height: 3px;
		display: block;
		background: #000;
		content: '';
		transition: top 0.3s 0.3s ease-in-out, bottom 0.3s 0.3s ease-in-out,
			transform 0.3s ease-in-out;
	}

	span {
		top: 8px;
		transition: all 0.2s ease;
		opacity: 1;
		visibility: visible;
	}

	&::before {
		top: 1px;
	}

	&::after {
		bottom: 1px;
	}

	@include when-inside('.page-header.is-opened') {
		&::before,
		&::after {
			transition: top 0.3s ease-in-out, bottom 0.3s ease-in-out,
				transform 0.3s 0.3s ease-in-out;
		}

		&::before {
			top: 8px;
			transform: rotate(45deg);
		}

		&::after {
			bottom: 8px;
			transform: rotate(-45deg);
		}

		span {
			opacity: 0;
			visibility: hidden;
		}
	}
}
