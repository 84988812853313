.sh-resources {
    $r: &;

    padding-bottom: 30px;

    @media (min-width: 768px) {
        padding-bottom: 100px;
    }

    &__header {
        text-align: center;
        padding-top: 120px;
        max-width: 705px;
        width: calc(100% - 40px);
        margin-left: auto;
        margin-right: auto;
        color: $heading-color;

        @media (min-width: 768px) {
            padding-top: 194px;
        }

        &-description {
            font-size: 18px;
            padding-top: 10px;
            padding-bottom: 55px;
        }

        h1 {
            @media (min-width: 1200px) {
                font-size: 56px;
            }
            @media (max-width: 575px) {
                font-size: 36px;
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        @media (min-width: 576px) {
            border-bottom: 1px solid rgba($heading-color, 0.2);
            padding-bottom: 13px;
            margin-bottom: 20px;
        }
    }

    &__filter {
        display: none;

        @media (min-width: 992px) {
            display: flex;
            align-items: center;
        }

        button {
            padding: 0;
            background-color: transparent;
            border: none;
            margin-left: 20px;
            margin-right: 20px;
            line-height: 42px;
            font-size: 16px;
            font-weight: 600;
            font-family: usual, Helvetica Neue Light, Helvetica Neue, Helvetica,
                Arial, sans-serif;
            color: $heading-color;
            transition: all 0.3s;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -14px;
                height: 0;
                background-color: $c-white;
                transition: all 0.3s;
                z-index: 3;
            }

            &:hover,
            &.is-checked {
                color: $c-white;

                &:before {
                    height: 3px;
                }
            }
        }

        &-select {
            @media (min-width: 992px) {
                display: none;
            }
        }
    }

    // SELECT
    &__select {
        padding-left: 20px;
        padding-right: 20px;
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 10px;

        @media (min-width: 576px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media (min-width: 992px) {
            padding-left: 0;
            padding-right: 0;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
            padding-bottom: 0;
        }

        .select2-container {
            width: 100%;

            @media (min-width: 992px) {
                width: 186px;
            }

            .select2-selection--single {
                border: none !important;
                background: transparent;
                border-radius: 6px;
                height: 42px;
                box-shadow: inset 0 0 0 2px rgba($heading-color, 0.5);
                transition: all 0.3s;

                @media (min-width: 992px) {
                    background: rgba($heading-color, 0.1);
                    box-shadow: none;
                }
            }
        }

        .select2-container--default.select2-container--open
            .select2-selection--single {
            background: $c-white;
            box-shadow: none;

            .select2-selection__placeholder,
            .select2-selection__rendered {
                color: rgba($heading-color, 0.5);
            }
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__rendered {
            line-height: 42px;
            padding-left: 20px;
            color: $heading-color;
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__placeholder {
            font-size: 16px;
            color: $heading-color;
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__arrow {
            height: 100%;
            top: 0;
            right: 22px;
            width: 18px;
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__arrow
            b {
            border: none;
            margin: 0;
            transform: translate(-50%, -50%);
            width: 18px;
            height: 9px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            background-image: url(../img/res-arrow.svg);
            transition: all 0.3s;
        }

        .select2-container--default.select2-container--open
            .select2-selection--single
            .select2-selection__arrow
            b {
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }

    // ITEMS
    &__items {
        max-width: 1860px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;

        &-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: -20px;
            margin-right: -20px;
        }

        &-col {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 20px;

            @media (min-width: 576px) {
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media (min-width: 992px) {
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
            }
            @media (min-width: 1360px) {
                flex: 0 0 25%;
                max-width: 25%;
            }
            @media (min-width: 1600px) {
                flex: 0 0 20%;
                max-width: 20%;
            }
            &.white-papers,
            &.ebooks,
            &.infographics {
                #{$r}__item {
                    $wpi: &;

                    &-img {
                        position: relative;
                        @include respond-to(sm) {
                            img {
                                filter: grayscale(1);
                            }
                        }
                    }
                    @include respond-to(sm) {
                        &:hover {
                            .sh-resources__item-img {
                                img {
                                    filter: grayscale(0);
                                }
                            }
                        }
                    }
                }
            }

            &.case-studies {
                #{$r}__item {
                    &-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include respond-to(sm) {
                            img {
                                width: 180px;
                                height: 180px;
                                border-radius: 50%;
                                filter: grayscale(1);
                            }
                        }
                    }
                    @include respond-to(sm) {
                        &:hover {
                            .sh-resources__item-img {
                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 0;
                                    filter: grayscale(0);
                                }
                            }
                        }
                    }
                }
            }

            &.webinars,
            &.videos {
                #{$r}__item {
                    &-img {
                        position: relative;
                        @include respond-to(sm) {
                            img {
                                filter: grayscale(1);
                            }
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 28px;
                            height: 32px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: contain;
                            background-image: url(../img/play.svg);
                            transition: all 0.3s;
                            z-index: 3;
                        }
                    }
                    @include respond-to(sm) {
                        &:hover {
                            .sh-resources__item-img {
                                img {
                                    filter: grayscale(0);
                                }
                                &:after {
                                    transform: translate(-50%, -50%) scale(1.2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__item {
        display: block;
        height: 100%;
        background: $c-white;
        box-shadow: 0 30px 60px rgba($heading-color, 0.5);
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.3s;
        @include respond-to(sm) {
            &:hover {
                .sh-resources__item-img img {
                    transform: scale(1.1);
                }
            }
        }
        &-img {
            width: 100%;
            height: 220px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
                transition: all 0.5s ease-in-out;
            }
        }

        &-content {
            padding: 35px 40px;
            background-color: $c-white;

            @media (min-width: 768px) {
                padding: 35px;
            }
        }

        &-type {
            text-transform: uppercase;
            color: $c-brand;
            font-size: 14px;
            line-height: 1;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        &-description {
            font-size: 14px;
            color: $heading-color;
        }
    }
}

.post-type-archive-resources,
.blog {
    .select2-dropdown {
        border: none;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
    }

    .select2-results__option--selectable {
        padding: 5px 20px;
        color: $heading-color;
    }

    .select2-container--default
        .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: $heading-color;
    }

    .select2-container--default .select2-results__option--selected {
        background-color: rgba($heading-color, 0.1);
    }

    .select2-container--default .select2-results > .select2-results__options {
        max-height: none;
    }
}
