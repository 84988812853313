.quote-shortcode {
    margin: 50px 0;
    padding: 20px;
    border-left: 4px solid $c-brand-a;
    .quote-heading {
        margin-bottom: 20px;
    }
    .quote-text {
    }
}
