.people-profile-details{
  background-color: #EBF6F4;
  position: relative;
  overflow: hidden;
  height: 0;

  &__social{
    width: 25px;;
  }

  &__description{
    max-height: 235px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 20px;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #ebf6f4;
    }

    &::-webkit-scrollbar-thumb {
      background: #153343c2;
      border-radius: 20px;

    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__inner{
    padding-top: 98px;
    padding-bottom: 91px;
    position: relative;

    @include respond-to(md){
      display: flex;
      align-items: center;
      padding-top: 104px;
      padding-bottom: 104px;;
    }

    @include respond-to(xl){
      padding-top: 184px;
      padding-bottom: 184px;
    }
  }

  &__text-wrap{
    position: relative;





    @include respond-to(md){
      width: 50%;
      padding-right: 13%;
    }
    h4{
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 14px;
    }

    h1{
      margin-bottom: 20px;
      font-size: 44px;

      @include respond-to(xl){
        margin-bottom: 36px;
      }
    }

    p{

      margin-bottom: 40px;

      @include respond-to(xl){
        margin-bottom: 36px;
      }
    }


  }

  &__white-circle{
    display: none;

    @include respond-to(md){
      display: block;
      height: 1100px;
      width: 1100px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      right: 52%;
      top: -226px;
      transform: scale(0.75) translateX(-200px);
      transform-origin: top;

    }

    @include respond-to(xl){
      height: 1400px;
      width: 1400px;
      top: -324px;
    }
  }

  &__close-btn{
    position: absolute;
    top: -15px;
    padding: 15px;
    right: -15px;
    z-index: 9;

    @include on-event{
      cursor: pointer;
    }

    @include respond-to(md){
      right: -100%;
    }
  }

  &__image-wrap{
    position: relative;
    margin-top: 40px;

    @include respond-to(md){
      margin-top: 0px;
      width: 50%;
      align-self: center;
    }

    img{

      margin: auto;
      width: 303px;
      height: 303px;
      object-fit: cover;

      @include respond-to(md){
        width: 380px;
        height: 380px;
        object-fit: cover;
      }
    }
  }
}

.dotted-circle--people-profile{
  top: -55px;;
  left: calc(50% - 210px) ;
  width: 420px;
  height: 420px;

  svg{
    circle{
      stroke-width: 0.2;
    }
  }

  @include respond-to(md){
    top: -56px;
    left: calc(50% - 247px) ;
    width: 494px;
    height: 494px;
  }
}
