.logos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include respond-to(sm) {
    width: 100%;
  }

  &__item {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
		  padding-bottom: 20;
    }

    img {
      max-width: 80%;
      padding-bottom: 20px;
      transform: scale(0.75);
    }

    @include respond-to(sm) {
      margin-top: 0;
      width: 25%;

      &:nth-child(2) {
        transition-delay: 0.3s;
      }

      &:nth-child(3) {
        transition-delay: 0.6s;
      }

      &:nth-child(4) {
        transition-delay: 0.9s;
      }

      img {
        transform: scale(1);
      }
    }
  }
}
