.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.49);
  @include transition;
  opacity: 0;
  visibility: hidden;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__inner {
    position: relative;
    padding: 75px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 780px;
    background-color: #fff;
    transform: translateY(10vh);
    @include transition;

    @include respond-to(sm) {
      width: 85%;
    }

    @include when-inside('.popup.is-active') {
      transform: translateY(0);
    }

    h2 {
      margin-bottom: 35px;
      max-width: 570px;
      text-align: center;
    }
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 20px;
    height: 20px;
    background-image: url('../img/icon-cross.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
