.v3-profit {
    background-color: #66d4d6;
    height: 520px;
    position: relative;
    animation: dottedCircle 2s infinite;

    @media (min-width: 560px) and (max-width: 1014px) {
        height: 600px;
    }

    @include respond-to(md) {
        display: flex;
        height: 539px;
    }

    &__inner {
        padding-top: 8rem;
        padding-bottom: 6rem;
        z-index: 9;
        position: relative;
    }

    &__text-wrap {
        h1,
        p {
            padding-bottom: 3rem;
        }
        & .btn {
            width: 100%;
            border-radius: 6px;

            @include respond-to(md) {
                width: auto;
            }
        }

        &btn:hover {
            opacity: 0.8;
        }

        @include respond-to(sm) {
            width: 54%;
        }
    }

    &__bg {
        position: absolute;
        bottom: -24px;
        right: 0px;
        z-index: 1;

        @include respond-to(sm) {
            bottom: -106px;
        }

        @media (min-width: 560px) and (max-width: 1014px) {
            bottom: -55px;
        }
    }
}

.features__dotted-v3-footer {
    display: none;

    @include respond-to(lg) {
        position: absolute;
        top: 573px;
        left: auto;
        right: 748px;
        display: block;
        width: 219px;
        height: 219px;
    }
}

.features_dotted-footer {
    display: none;

    @include respond-to(lg) {
        display: block;
    }
    svg {
        height: 234px;
        position: absolute;
        right: 738px;
        top: 209px;
        animation: pulseCircle 2s infinite;
    }

    circle {
        width: 100%;
        height: 100%;
        fill: transparent;
        stroke: $heading-color;
        stroke-width: 0.5;
        stroke-dasharray: 0.2, 5;
        stroke-linecap: round;
    }
}
