.image-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    @include transition;

    &.is-selected {
        pointer-events: all;
        opacity: 1;
    }

    &__inner {
        position: relative;
        padding-top: 12vh;
        padding-bottom: 50px;
        height: 100%;

        @include respond-to(md) {
            @include respond-to(landscape) {
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;

                @include when-inside('.image-text--2') {
                    flex-direction: row;
                }
            }
        }
    }

    &__circle-white {
        position: absolute;
        width: 140vw;
        height: 140vw;
        left: 50%;
        bottom: -100px;
        opacity: 0;
        transform: translateX(-50%) scale(0.75);

        @include respond-to(md) {
            @include respond-to(landscape) {
                top: 50%;
                left: -11vh;
                width: 110vw;
                height: 110vw;
                transform: translateY(-50%) scale(0.75);

                @include when-inside('.image-text--2') {
                    left: auto;
                    right: -11vh;
                }
            }
        }

        @include respond-to(xxl) {
            width: 85vw;
            height: 85vw;
        }

        @include when-inside('.is-selected') {
            opacity: 1;
            transform: translateX(-50%) scale(1);
            @include transition-circle;

            @include respond-to(md) {
                @include respond-to(landscape) {
                    transform: translateY(-50%) scale(1);
                }
            }
        }
    }

    &__text-wrap {
        position: relative;
        text-align: center;

        > * {
            position: relative;
            z-index: 3;
        }

        h2 {
            margin-bottom: 15px;
        }

        h2,
        p,
        .btn {
            opacity: 0;
            @include transition;
        }

        p {
            transition-delay: 0.3s;
        }

        .btn {
            display: none;
            transition-delay: 0.6s;
        }

        @include when-inside('.is-selected') {
            h2,
            p,
            .btn {
                opacity: 1;
                z-index: 5;
            }

            .btn {
                &:hover,
                &:focus {
                    transition-delay: 0s;
                    opacity: 0.8;
                }
            }
        }

        @include respond-to(sm) {
            margin-left: auto;
            margin-right: auto;
            width: 360px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn {
                margin-top: 30px;
                display: block;
            }
        }

        @include respond-to(md) {
            @include respond-to(landscape) {
                margin-right: 0;
                margin-left: 0;
                text-align: left;
                align-items: flex-start;
            }
        }
    }

    &__img {
        position: relative;
        z-index: 3;
        margin-top: 8vh;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        max-width: none;
        width: 100vw;
        opacity: 0;
        transform: translateX(-10%);
        @include transition;

        @include when-inside('.is-selected') {
            opacity: 1;
        }

        @include respond-to(md) {
            @include respond-to(landscape) {
                position: absolute;
                top: 50%;
                right: 430px;
                width: 60vw;
                transform: translateY(-50%);
            }
        }

        // @include respond-to(xl) {
        //     width: 55vw;
        // }

        @include when-inside('.image-text--1') {
            transform: translateX(-10%);

            @include respond-to(md) {
                @include respond-to(landscape) {
                    transform: translateY(-50%);
                }
            }
        }

        @include when-inside('.image-text--2') {
            @include respond-to(md) {
                @include respond-to(landscape) {
                    right: 100px;
                    width: 40vw;
                }
            }
            @include respond-to(xl) {
                right: -200px;
            }
        }

        @include when-inside('.image-text--3') {
            width: 110vw;

            @include respond-to(sm) {
                width: 100vw;
            }

            @include respond-to(md) {
                @include respond-to(landscape) {
                    width: 40vw;
                    right: 600px;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &__dotted {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 360px;
        height: 360px;
        display: none;
        transform: translate(-50%, -50%);

        svg circle {
            stroke-width: 0.33;
            stroke-dasharray: 0.15, 3;
        }

        @include respond-to(sm) {
            display: block;
        }
    }
}
