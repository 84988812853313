.process {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  overflow: hidden;
  background-color: $brand-mid;

  @include respond-to(sm) {
    padding-top: 120px;
    padding-bottom: 110px;
  }

  &__inner {
    position: relative;
    z-index: 2;

    h2 {
      margin-bottom: 60px;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to(sm) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__item {
    width: 265px;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 1.8rem;
      font-weight: 600;
      animation: bounce 0.7s both;
    }

    p {
      opacity: 0;
      @include transition-slow(opacity);
      transition-delay: 0.5s;
    }

    &.is-visible {
      p {
        opacity: 1;
      }
    }

    @include respond-to(sm) {
      position: relative;
      padding-left: 5px;
      padding-right: 5px;
      width: 33.33%;

      &:not(:last-child) {
        margin-bottom: 0;

        &::before {
          position: absolute;
          top: 32px;
          left: 50%;
          width: 100%;
          background-image: url('../img/dotted-line.svg');
          background-repeat: no-repeat;
          background-size: 700px 2px;
          height: 2px;
          transition: transform 0.5s 0.6s ease-in;
          transform-origin: left;
          transform: scaleX(0);
          content: '';
        }

        &.is-visible {
          &::before {
            transform: scaleX(1);
          }
        }
      }

      &:nth-child(2) {
        h3 {
          animation-delay: 1.5s;
        }

        p {
          transition-delay: 2s;
        }

        &::before {
          transition-delay: 2.5s;
        }
      }

      &:nth-child(3) {
        h3 {
          animation-delay: 3s;
        }

        p {
          transition-delay: 3.5s;
        }

        &::before {
          transition-delay: 4s;
        }
      }

      &:nth-child(4) {
        h3 {
          animation-delay: 4.5s;
        }

        p {
          transition-delay: 5s;
        }

        &::before {
          transition-delay: 5.5s;
        }
      }
    }

    @include respond-to(md) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include respond-to(xl) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__icon {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $heading-color;
    transform: scale(0);
    @include transition-slow;

    @include when-inside('.is-visible') {
      animation: bounce-in-fwd 1.1s both;
    }

    @include respond-to(sm) {
      margin-bottom: 44px;

      @include when-inside('.process__item:nth-child(2)') {
        animation-delay: 1.5s;
      }

      @include when-inside('.process__item:nth-child(3)') {
        animation-delay: 3s;
      }

      @include when-inside('.process__item:nth-child(4)') {
        animation-delay: 4.5s;
      }
    }
  }
}
