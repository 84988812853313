// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
    display: inline-block;
    padding: 12px 28px;
    color: #ffffff;
    font-family: $text-font-stack;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.1;
    border-radius: 6px;
    background-color: $heading-color;
    backface-visibility: hidden;
    border: none;
    outline: none;
    @include transition;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: $c-brand-a;
        color: $heading-color;
    }
    &--dark_bg {
        &:hover,
        &:focus {
            background-color: $c-white;
            color: $heading-color;
        }
    }
    &--secondary {
        color: $heading-color;
        background-color: transparent;
        border: 2px solid $heading-color;

        &:hover,
        &:focus {
            color: #fff;
            opacity: 1;
            background-color: $heading-color;
        }
    }
    &--submit {
        background-color: $brand-mid;
        color: $heading-color;
        border-radius: 6px;
        border: 0;
        // font-family: $text-font-stack;
        font-weight: 600;
        font-size: 16px;
        // color: $heading-color;
        cursor: pointer;
        line-height: 1.25;
        padding: 11px 32px;
        margin-bottom: 30px;
        transition: all 0.3s ease-out;

        // &:hover {
        //     background-color: rgba($brand-mid, 0.4);
        // }
    }
    // Outline button
    &-disabled,
    &-outline {
        padding: 10px 28px;
        color: $heading-color;
        border: 2px solid $heading-color;
        background: #0000;
        font-family: $text-font-stack;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.1;
        border-radius: 6px;
        &:hover,
        &:focus {
            background: $c-brand-a;
            border-color: $c-brand-a;
            color: $heading-color;
        }
    }
    &-disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }
    // Link
    &-link {
        font: 400 18px/1.6 $text-font-stack;
        color: $heading-color;
        text-decoration: underline;
        &:hover,
        &:focus {
            color: #000204;
        }
    }
}
.cta-shortcode {
    display: inline-block;
    margin: 10px;
}
