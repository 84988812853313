.chart {
  position: relative;
  padding-top: 70px;
  padding-bottom: 60px;
  background-color: $brand-light;

  @include respond-to(sm) {
    padding-top: 110px;
    padding-bottom: 115px;
  }

  &__inner {
    @include respond-to(sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__text-wrap {
    position: relative;
    z-index: 2;
    margin-bottom: 80px;

    h2 {
      margin-bottom: 10px;
    }

    @include respond-to(sm) {
      margin-bottom: 0;
      width: 41.66%;
      max-width: 460px;

      h2 {
        margin-bottom: 25px;
      }
    }
  }

  &__bars {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;

    img,
    video {
      width: 100%;
      border: none;
    }

    @include respond-to(sm) {
      width: calc(58.33% - 50px);
    }
  }

  &__caption {
    margin-top: 40px;
    display: flex;
    font-size: 1.6rem;

    span {
      width: 50%;
      display: inline-block;
      text-align: center;
    }

    @include respond-to(sm) {
      margin-top: 25px;
    }

    @include respond-to(md) {
      font-size: 1.8rem;
    }
  }

  &__dotted-1 {
    position: absolute;
    top: 135px;
    left: -175px;
    width: 500px;
    height: 500px;
    display: none;

    svg circle {
      stroke-width: 0.24;
      stroke-dasharray: 0.1, 2;
    }

    @include respond-to(sm) {
      top: 100px;
      display: block;
    }
  }

  &__dotted-2 {
    position: absolute;
    top: 270px;
    left: -45px;
    width: 235px;
    height: 235px;
    display: none;

    svg circle {
      stroke-width: 0.5;
      stroke-dasharray: 0.25, 3.8;
    }

    @include respond-to(sm) {
      top: 240px;
      display: block;
    }
  }

  &__dotted-3 {
    display: none;

    @include respond-to(lg) {
      position: absolute;
      bottom: 50px;
      right: -60px;
      z-index: 5;
      width: 230px;
      height: 230px;
      display: block;

      svg circle {
        stroke-width: 0.54;
        stroke-dasharray: 0.25, 3.8;
      }
    }
  }
}
