// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */

  @include respond-to(sm) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden,
.screen-reader-response {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

html.disable-scrolling {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

/**
* Embed Responsively
* http://embedresponsively.com/
*/
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Scroll reveal */
.is-observer {
  .reveal-slide {
    opacity: 0;
    transform: translateY(40px);
    @include transition-reveal(transform);
  }



  .reveal-slide-left {
    opacity: 0;
    transform: translateX(-40px);
    @include transition-reveal(transform);
  }

  .reveal-slide-right {
    opacity: 0;
    transform: translateX(40px);
    @include transition-reveal(transform);
  }

  .reveal-fade {
    opacity: 0;
    @include transition-reveal(opacity);
  }

  .is-visible {
    &.sticker-pop-up{
      animation: sticker-pop-in 0.5s forwards;
      animation-delay: 1.6s;
    }
    &.reveal-del-1,
    .reveal-del-1 {
      transition-delay: 0.4s;
    }
    &.reveal-del-2,
    .reveal-del-2 {
      transition-delay: 0.7s;
    }
    &.reveal-del-3,
    .reveal-del-3 {
      transition-delay: 1s;
    }
    &.reveal-del-4,
    .reveal-del-4 {
      transition-delay: 1.3s;
    }

    .reveal-slide,
    &.reveal-slide {
      opacity: 1;
      transform: translateY(0);
    }

    .reveal-slide-right,
    &.reveal-slide-right,
    .reveal-slide-left,
    &.reveal-slide-left {
      opacity: 1;
      transform: translateX(0);
    }

    .reveal-fade,
    &.reveal-fade {
      opacity: 1;
    }
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-fwd
 * ----------------------------------------
 */

@keyframes bounce-in-fwd {
  0% {
    transform: scale(0);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: scale(1);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: scale(0.7);
    animation-timing-function: ease-in;
  }
  72% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  81% {
    transform: scale(0.84);
    animation-timing-function: ease-in;
  }
  89% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  95% {
    transform: scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes bounce {
  0% {
    transform: scale(0);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  72% {
    transform: scale(1.4);
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}


///sticker pop in
@keyframes sticker-pop-in {
  0% {opacity: 0.1; transform: scale(0.4)rotate(-28deg)}
  80% {opacity: 1; transform: scale(1.2) rotate(0deg)}
  100% {opacity: 1; transform: scale(1)}
}
