.sh-single {
    background-color: $c-brand-b;

    &__info {
        background-color: $c-white;
        text-align: center;
        padding: 120px 0 95px;

        &-header {
            margin-bottom: 30px;
        }

        &-author {
            font-size: 14px;
            color: $heading-color;
            a {
                font-size: 14px;
                color: $heading-color;
                margin-left: 4px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-date {
            font-size: 14px;
            color: $heading-color;
        }

        &-author {
            display: flex;
            align-items: center;
            margin: 0 auto;
            width: max-content;

            img {
                border-radius: 50%;
                margin-right: 20px;
                width: 48px;
                height: 48px;
            }
        }

        &-info {
            margin-bottom: 15px;
        }

        &-category {
            font-size: 14px;
            color: $brand-mid;
            text-transform: uppercase;
            margin-right: 10px;
            @include underline;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 56px;
    }

    &__excerpt {
        font-weight: 400;
        font-size: 24px;
        margin: 0 auto;
        max-width: 640px;
    }

    &__title,
    &__excerpt {
        color: $heading-color;
        line-height: 1.25;
        margin-bottom: 35px;
    }

    &__img {
        @include img-responsive();
        width: 100%;
    }

    &__content {
        padding: 55px 0 100px;
        margin: 0 auto;
        max-width: 705px;
        > div {
            max-width: 100%;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 2.4rem;
        }
        h1 {
            @include respond-to(sm) {
                font-size: 5.6rem;
                line-height: 7rem;
            }
        }
        h2 {
            @include respond-to(sm) {
                font-size: 3.6rem;
                line-height: 1;
            }
        }

        h3 {
            font-size: 2rem;
            line-height: 1.6;
        }

        h4 {
            font-size: 1.8rem;
            line-height: 1.6;
            font-weight: bold;
        }

        h5,
        h6 {
            font-size: 1.6rem;
            line-height: 1.6;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: bold;
        }

        h6 {
            opacity: 0.5;
        }
        ol,
        ul {
            margin-bottom: 35px;
            padding-left: 20px;
        }
        ul {
            list-style: disc;
        }

        li {
            color: $heading-color;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        p {
            &:not(:last-child) {
                margin-bottom: 3rem;
            }

            a {
                color: $c-brand;
                text-decoration: underline;
                &:hover {
                    color: $heading-color;
                }
            }
        }

        img {
            @include img-responsive();
            margin-bottom: 20px;

            &.alignleft {
                float: left;
                margin-right: 15px;
            }

            &.alignright {
                float: right;
                margin-left: 15px;
            }
        }
        video {
            width: 100%;
            height: 100%;
        }
    }

    &__share {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
            font-weight: 600;
            font-size: 16px;
            color: $heading-color;
            line-height: 1;
            margin-right: 20px;
        }

        ul {
            display: flex;
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            li {
                margin-right: 10px;
                margin-bottom: 0 !important;
            }

            a {
                transition: all 0.25s ease;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    &__tags {
        a {
            font-weight: 600;
            font-size: 16px;
            line-height: 1;
            color: $c-brand;
            margin-right: 20px;
            text-decoration: none !important;
            @include underline;

            &::before {
                content: '#';
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (max-width: 991px) {
        &__info {
            padding: 100px 0 50px;

            &-header {
                margin-bottom: 20px;
            }
        }

        &__title {
            font-size: 42px;
        }

        &__excerpt {
            font-size: 20px;
        }

        &__title,
        &__excerpt {
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 767px) {
        &__title {
            font-size: 32px;
        }

        &__excerpt {
            font-size: 18px;
        }

        &__content {
            margin: 55px auto;
        }
    }

    &__related {
        position: relative;

        &-cover {
            @include img-responsive();
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-100%) rotate(90deg);
            z-index: 0;
            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        &-heading {
            font-weight: 700;
            font-size: 60px;
            color: $heading-color;
            line-height: 1;
            text-align: center;
            position: absolute;
            top: -35px;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            z-index: 1;
        }
        &-inner {
            position: relative;
            background-color: $brand-mid;
            padding: 165px 0;
            margin: 0 40px;
            overflow: hidden;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -20px;
            margin-right: -20px;
            position: relative;
        }

        &-item {
            padding-left: 20px;
            padding-right: 20px;
            width: 33.3333%;
        }

        &-img {
            display: block;
            margin: 0 auto 30px;
            position: relative;
            width: 180px;
            height: 180px;

            img {
                border-radius: 50%;
                filter: grayscale(1);
                transition: all 0.25s ease;
                @include objectFit();
            }

            &:hover {
                img {
                    filter: grayscale(0);
                }
            }
        }

        &-title {
            font-weight: 700;
            font-size: 24px;
            color: $heading-color;
            line-height: 1.1;
            margin-bottom: 15px;

            a {
                color: $heading-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-content {
            background-color: $c-white;
            box-shadow: 0 30px 60px rgba($heading-color, 0.5);
            padding: 45px 40px 40px;
            height: 100%;
        }

        &-author,
        &-date {
            font-size: 14px;
            color: $heading-color;

            a {
                color: $heading-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-header {
            margin-bottom: 15px;
            br {
                display: block;
            }
        }

        &-category {
            font-size: 14px;
            color: $c-brand;
            text-transform: uppercase;
            margin-right: 10px;
            @include underline;
        }

        @media screen and (max-width: 1199px) {
            &-inner {
                padding: 125px 0;
                margin: 0 20px;
            }

            &-list {
                margin-left: -15px;
                margin-right: -15px;
            }

            &-item {
                padding-left: 15px;
                padding-right: 15px;
            }

            &-content {
                padding: 35px 25px;
            }
        }

        @media screen and (max-width: 991px) {
            &-inner {
                padding: 75px 0;
            }

            &-item {
                margin-bottom: 20px;
                width: 100%;
            }

            &-header {
                br {
                    display: none;
                }
            }
        }

        @media screen and (max-width: 767px) {
            &-inner {
                padding: 50px 0;
                margin: 0;
            }

            // &-heading {
            //     text-align: center;
            //     padding: 0 15px;
            //     margin-bottom: 25px;
            //     position: static;
            //     transform: none;
            //     width: 100%;
            // }
        }
    }
}
