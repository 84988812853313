.v3-hero{

  &__auto-type{
    height: 88px;

    @include respond-to(sm){
      height: auto;
    }
  }

  &__inner{
    margin-top: 111px;
    margin-bottom: 50px;
    max-width: 808px;    

    @include respond-to(lg){
      margin-top: 156px;
      margin-bottom: 107px;
    }

    h1, span{
      font-size: 48px;
      line-height: 1.2;
      margin-bottom: 5px;

      @include respond-to(lg){
        font-size: 56px;
      }
    }

    & .typed-cursor {
      color: #fff;
    }
    
    & .typing{
      color: #fff;
      font-weight: 700;
    }

    p{
      margin-top: 36px;
      margin-bottom: 50px;
      font-size: 18px;
      max-width: 665px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__text-wrap{
    text-align: center;
  }

  & .btn{
    width: 100%;    
    font-size: 16px; 
    max-width: 400px;
    
    @include on-event{
      opacity: 0.8;
    }
    
    @include respond-to(sm){
      width: 250px;
    }
  }
}

html #wpadminbar {
  height: 46px;
  min-width: 240px;
  display: none;
}
