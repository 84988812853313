body.single-episode {
    main {
        background: $c-white;

        #header {
            background-image: url(../img/fresh-thinking-detail-watermelon.png);
            background-repeat: no-repeat;
            background-position: calc(50% + 930px) 10px;

            &__logo {
                max-width: 300px;
                margin-top: 55px + 72px;
                margin-left: auto;
                margin-right: auto;

                @media screen and (max-width:  768px) {
                    width: calc(70% - 40px);
                    max-width: 225px;
                    margin-top: 90px;
                }
            }

            &__subtitle {
                margin-top: 39px;
                font-size: 14px;
                text-align: center;
                text-transform: uppercase;
                line-height: 100%;
                color: $c-brand;
            }

            &__title {
                max-width: 1078px + 40px * 2;
                margin: 40px auto 0;
                padding: 0 40px;
                font-size: 56px;
                font-weight: 700;
                text-align: center;
                line-height: 125%;
                color: $heading-color;

                @media screen and (max-width: 768px) {
                    padding: 0 20px;
                    font-size: 30px;
                }
            }

            &__video {
                max-width: 1078px + 40px * 2;
                margin: 68px auto -1px;
                padding: 0 40px;

                @media screen and (max-width: 768px) {
                    padding: 0 20px;
                }
                
                &__inner {
                    position: relative;
                    padding-bottom: math.div(9, 16) * 100%;
                    background: transparent;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        #description {
            padding: 37px 40px 97px;
            background: $brand-light;

            @media screen and (max-width: 768px) {
                padding-top: 20px;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 49px;
            }

            &__listen {
                display: flex;
                align-items: center;
                justify-content: space-between;
                max-width: 1078px;
                margin: 0 auto;

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                &__left {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    @media screen and (max-width: 768px) {
                        align-items: flex-start;
                    }

                    > span {
                        margin-right: 20px;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 160%;
                        color: $heading-color;
                    }

                    .platform {
                        display: flex;
                        align-items: center;
                        margin-right: 40px;

                        @media screen and (max-width: 768px) {
                            margin-right: 20px;
                            margin-bottom: 10px;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        &:hover {
                            span {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }

                        img {
                            margin-right: 10px;

                            @media screen and (max-width: 768px) {
                                width: 20px;
                            }
                        }

                        span {
                            display: block;
                            position: relative;
                            font-size: 16px;
                            font-weight: 600;
                            color: $heading-color;

                            @media screen and (max-width: 768px) {
                                font-size: 14px;
                            }
                            
                            &:after {
                                content: '';
                                position: absolute;
                                bottom: -2px;
                                left: 0;
                                width: 0;
                                height: 2px;
                                background: $heading-color;
                                transition: width 0.4s;
                            }
                        }
                    }
                }

                &__right {
                    margin-bottom: 0;

                    @media screen and (max-width: 768px) {
                        margin-top: 20px;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 700;
                    }
                    
                    ul {
                        display: flex;
                        align-items: center;

                        li {
                            margin-right: 20px;

                            &:last-child {
                                margin-right: 0;
                            }

                            a {
                                svg {
                                    path {
                                        fill: $heading-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__copy {
                margin: 97px auto 0;
                max-width: 1078px;
                font-size: 18px;
                line-height: 160%;
                color: $heading-color;

                @media screen and (max-width: 768px) {
                    margin-top: 49px;
                }

                p {
                    margin-bottom: 29px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &__read_transcript {
                max-width: 1078px;
                margin: 37px auto 0;

                a {
                    text-decoration: underline;
                    color: $c-brand;
                }
            }
        }

        #speakers {
            padding: 80px 0 100px;
            background: $c-brand-a;

            @media screen and (max-width: 768px) {
                padding: 40px 0 50px;
            }

            &__inner {
                max-width: 1078px + 40px * 2;
                margin: 0 auto;
                padding: 0 40px;

                @media screen and (max-width: 768px) {
                    padding: 0 20px;
                }

                h1 {
                    font-size: 60px;
                    text-align: center;

                    @media screen and (max-width: 768px) {
                        font-size: 30px;
                    }
                }

                .speaker {
                    display: flex;
                    align-items: center;
                    margin-top: 80px;

                    @media screen and (max-width: 900px) {
                        align-items: flex-start;
                    }
                    
                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                        margin-top: 50px;
                    }

                    &--image {
                        flex: 0 0 auto;
                        width: 332px;
                        height: 332px;
                        margin-right: 41px;
                        background-color: $heading-color;
                        background-size: cover;
                        border-radius: 332px;

                        @media screen and (max-width: 900px) {
                            width: 200px;
                            height: 200px;
                        }

                        @media screen and (max-width: 768px) {
                            margin-right: 0;
                            margin-bottom: 40px;
                        }
                    }

                    &--right {
                        flex: 1;

                        h2 {
                            font-size: 36px;
                            font-weight: 700;
                            line-height: 100%;
                            color: $heading-color;

                            @media screen and (max-width: 768px) {
                                font-size: 24px;
                            }
                        }

                        h3 {
                            margin-top: 24px;
                            font-size: 18px;
                            line-height: 160%;
                            color: $heading-color;
                        }

                        p {
                            margin-top: 24px;
                            font-size: 18px;
                            line-height: 160%;
                            color: $heading-color;
                        }

                        a {
                            display: block;
                            margin-top: 16px;
                        }
                    }
                }

                &__learn_more {
                    margin-top: 114px;
                    text-align: center;

                    @media screen and (max-width: 768px) {
                        margin-top: 50px;
                    }
        
                    a {
                        &:hover {
                            background-color: $brand-light;
                        }
                    }
                }
            }
        }

        #transcript {
            padding: 96px 0 47px;
            background: $brand-light;

            @media screen and (max-width: 768px) {
                padding: 48px 0 24px;
            }

            h2 {
                max-width: 1078px + 40px * 2;
                margin: 0 auto;
                padding: 0 40px;
                font-size: 20px;
                font-weight: 600;
                line-height: 160%;
                color: $heading-color;

                @media screen and (max-width: 768px) {
                    padding: 0 20px;
                }
            }

            div {
                position: relative;
                max-width: 1078px + 40px * 2;
                max-height: 116px;
                overflow: hidden;
                margin: 30px auto 0;
                padding: 0 40px;
                font-size: 18px;
                line-height: 160%;
                color: $heading-color;

                @media screen and (max-width: 768px) {
                    padding: 0 20px;
                }

                &.open {
                    height: auto;
                    max-height: 100%;

                    #transcript__show_more {
                        display: none;
                    }

                    #transcript__show_less {
                        display: inline;
                    }
                }

                p {
                    padding: 14px 0;

                    &:first-of-type {
                        padding-top: 0;
                    }

                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }
            }

            &__show_more {
                position: absolute;
                bottom: 0;
                right: 40px;
                padding-left: 1em;
                text-decoration: underline;
                color: $c-brand;
                background: $brand-light;

                @media screen and (max-width: 768px) {
                    right: 20px;
                }
            }

            &__show_less {
                display: none;
                text-decoration: underline;
                color: $c-brand;
            }
        }

        #newsletter {
            &__inner {
                background-image: url(../img/fresh-thinking-form-right2.png);
            }
        }
    }
}