.hero {
    position: relative;
    height: 100vh;
    min-height: 768px;
    overflow: hidden;

    &__btn.btn {
        position: absolute;
        bottom: 18vh;
        left: 50%;
        z-index: 3;
        padding-top: 16px;
        padding-bottom: 16px;
        width: calc(100% - 40px);
        transform: translateX(-50%);

        @include respond-to(sm) {
            display: none;
        }
    }

    &__dots {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .dot {
            margin-left: 3px;
            margin-right: 3px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            opacity: 0.2;
            background-color: $text-color;
            cursor: pointer;

            &.is-active {
                opacity: 1;
            }
        }
    }
}
