// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    } @else {
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($classes...) {
    @each $class in $classes {
        #{$class} & {
            @content;
        }
    }
}

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin for-size($size) {
   @if $size == phone {
     @media (max-width: 800px) { @content; }
   } @else if $size == tablet {
     @media (max-width: 1000px) { @content; }
   } @else if $size == desktop {
     @media (max-width: 1300px) { @content; }
   } @else if $size == desktop-small {
     @media (max-width: 1600px) { @content; }
   } @else if $size == big-desktop {
     @media (min-width: 1800px) { @content; }
   }
}

@mixin transition($prop: all) {
    transition: $prop 0.3s ease-in-out;
}

@mixin transition-slow($prop: all) {
    transition: $prop 0.6s ease-in-out;
}

@mixin transition-circle($prop: all) {
    transition: opacity 0.5s cubic-bezier(0.33, 0, 0.67, 1),
        transform 1s cubic-bezier(0.33, 0, 0, 1);
}

@mixin transition-reveal($prop: all) {
    transition: $prop 1s cubic-bezier(0.405, 0.005, 0, 1);
}

@mixin img-responsive() {
    display: block;
    height: auto;
    max-width: 100%;
}

@mixin objectFit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

@mixin underline {
    position: relative;
    line-height: 1.3;

    &::after {
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: currentColor;
        transform: scaleX(0);
        transform-origin: left;
        @include transition;
        content: '';
    }

    &:hover {
        &::after {
            transform: scaleX(1);
        }
    }
}

@mixin transition-sequence($start, $interval, $children: '') {
    @for $i from 1 through 10 {
        @if $children != '' {
            &:nth-child(#{$i}) #{$children} {
                transition-delay: calc(#{$start} + #{$i} * #{$interval});
            }
        } @else {
            &:nth-child(#{$i}) {
                transition-delay: calc(#{$start} + #{$i} * #{$interval});
            }
        }
    }
}

@function get-vw($target) {
    @if (unitless($target)) {
        $target: $target * 1px;
    }
    $vw-context: (1920 * 0.01) * 1px;
    @return math.div($target, $vw-context) * 1vw;
}
