body.page-template-template-fresh-thinking,
body.single-episode {
    main {
        background-color: $c-white;

        #header {
            position: relative;

            &__logo {
                max-width: 300px;
                margin-top: 55px + 72px;
                margin-left: auto;
                margin-right: auto;

                @media screen and (max-width: 768px) {
                    width: calc(70% - 40px);
                    max-width: 225px;
                    margin-top: 90px;
                }
            }

            &__copy {
                max-width: 638px + 40px * 2;
                margin: 25px auto 0;
                padding: 0 40px;
                font-size: 18px;
                line-height: 29px;
                text-align: center;

                @media screen and (max-width: 768px) {
                    padding: 0 20px;
                }
            }

            &__logos {
                margin-top: 28px;
                padding: 0 40px 39px;

                @media screen and (max-width: 768px) {
                    margin-top: 14px;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 20px;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width: 768px) {
                        margin: 0 auto;
                    }

                    li {
                        margin-right: 40px;

                        @media screen and (max-width: 768px) {
                            margin-right: 20px;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        @media screen and (max-width: 768px) {
                            margin-bottom: 10px;
                        }

                        a {
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            font-weight: 600;
                            color: $heading-color;

                            @media screen and (max-width: 768px) {
                                font-size: 14px;
                            }

                            &:hover {
                                span {
                                    &:after {
                                        width: 100%;
                                    }
                                }
                            }

                            img {
                                margin-right: 10px;

                                @media screen and (max-width: 768px) {
                                    width: 20px;
                                }
                            }

                            span {
                                display: block;
                                position: relative;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    bottom: -2px;
                                    left: 0;
                                    width: 0;
                                    height: 2px;
                                    background: $heading-color;
                                    transition: width 0.4s;
                                }
                            }
                        }
                    }
                }
            }

            &__watermelon {
                position: absolute;
                top: 28px;
                left: calc(50% + 400px);
                width: 325px;
            }
        }

        #episodes {
            display: flex;
            justify-content: center;
            padding: 60px 42px 189px;
            background: $brand-light;

            @media screen and (max-width: 768px) {
                padding-top: 30px;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 50px;
            }

            &__inner {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                justify-content: center;
                position: relative;
                z-index: 3;
                width: calc(100% + 40px);
                max-width: 1820px;
                margin: -20px;

                .episode {
                    flex: 0 0 auto;
                    width: 33.33%;
                    padding: 20px;

                    @media screen and (max-width: 1070px) {
                        width: 50%;
                    }

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }

                    /*&:hover {
                        .episode {
                            &--wrapper {
                                &--preview {
                                    img {
                                        filter: grayscale(0);
                                        transform: scale(1.1);
                                    }
                                }
                            }
                        }
                    }*/

                    &--wrapper {
                        height: 100%;
                        overflow: hidden;
                        border-radius: 6px;
                        box-shadow: 0px 30px 60px rgba(21, 51, 67, 0.5);

                        &--preview {
                            position: relative;
                            overflow: hidden;
                            padding-bottom: math.div(326, 580) * 100%;
                            background-color: $heading-color;                            

                            img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;   
                                object-fit: cover;
                                object-position: center;
                                //filter: grayscale(1);
                                transition: all 0.5s ease-in-out;
                            }
                        }
    
                        &--bottom {
                            height: 100%;
                            padding: 20px 40px 52px;
                            background: $c-white;
    
                            &--subtitle {
                                font-size: 14px;
                                line-height: 100%;
                                text-transform: uppercase;
                                color: $c-brand;
                            }
    
                            &--title {
                                padding-top: 20px;
                                font-size: 24px;
                                font-weight: 700;
                                line-height: 100%;
                                color: $heading-color;
                            }
                        }   
                    }
                }
            }
        }

        #callout {
            background: $c-brand-a;

            &__inner {
                max-width: 1078px + 40px * 2;
                margin: 0 auto;
                padding: 90px 40px 121px;

                @media screen and (max-width: 768px) {
                    padding-top: 45px;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 60px;
                }

                h4 {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 160%;
                }

                h2 {
                    margin-top: 11px;
                    font-size:  56px;
                    font-weight: 700;
                    line-height: 125%;

                    @media screen and (max-width: 768px) {
                        font-size: 30px;
                    }
                }

                p {
                    margin-top: 40px;
                    font-size: 18px;
                    line-height: 160%;
                }
            }
        }

        #newsletter {
            padding: 125px 0 168px;
            background-color: $brand-light;
            background-image: url(../img/bg-form-watermelon.png);
            background-repeat: no-repeat;
            background-position: calc(50% + 492px) 64px;
            border-bottom: 1px solid rgba(#153343, 0.2);

            @media screen and (max-width: 768px) {
                padding-top: 63px;
                padding-bottom: 84px;
                background-position: calc(50% + 212px) 64px;
            }

            &__inner {
                position: relative;
                width: calc(100% - 80px);
                max-width: 1078px;
                margin: 0 auto;
                padding: 65px 300px 35px 60px;
                background-color: $c-white;
                background-image: url(../img/fresh-thinking-form-right.png);
                background-position: calc(100% - 42px) calc(100% + 70px);
                background-repeat: no-repeat;
                box-shadow: 0px 30px 60px rgba(21, 51, 67, 0.5);

                @media screen and (max-width: 808px) {
                    padding-right: 60px;
                    background-image: none !important;
                }

                @media screen and (max-width: 768px) {
                    width: calc(100% - 40px);
                    padding: 30px;
                }

                h2 {
                    font-size: 36px;
                    line-height: 100%;

                    @media screen and (max-width: 768px) {
                        font-size: 24px;
                    }
                }

                p {
                    margin-top: 28px;
                    font-size: 18px;
                    line-height: 125%;
                }

                .wpcf7 {
                    margin-top: 19px;

                    input[type=submit] {
                        @extend .btn;
                        margin-top: 58px;
                    }

                    .sh-mailchimp__form-input {
                        @media screen and (max-width: 968px) {
                            width: auto;
                            margin-right: 0;
                        }
                    }

                    .sh-demo__row  {
                        width: 520px;
                        margin: 0 0 30px;

                        @media screen and (max-width: 968px) {
                            flex-direction: column;
                            width: auto;
                            margin-right: 0;
                        }

                        .sh-demo__col {
                            padding: 0 10px;

                            @media screen and (max-width: 968px) {
                                width: 100%;
                                padding: 0;
                            }

                            &:first-child {
                                padding-left: 0;

                                @media screen and (max-width: 968px) {
                                    margin-bottom: 30px;
                                }
                            }

                            &:last-child {
                                padding-right: 0;
                            }

                            .sh-mailchimp__form-input {
                                width: auto;
                                margin-right: 0;
                            }
                        }
                    }
                }

                &__disclaimer {
                    p {
                        position: absolute;
                        bottom: 40px;
                        left: 220px;
                        width: calc(72% - 300px);
                        opacity: 0.75;
                        font-size: 12px;
                        line-height: 130%;

                        @media screen and (max-width: 768px) {
                            left: 180px;
                            width: auto;
                            padding-right: 20px;
                        }
                    }

                    a {
                        text-decoration: underline;
                        color: $heading-color;
                    }
                }
            }
        }
    }
}