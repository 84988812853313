.sh-faq-page {
    background-color: $brand-light;

    &__header {
        background-color: $brand-mid;
        padding-top: 194px;
        padding-bottom: 65px;
        text-align: center;

        @media (max-width: 580px) {
            padding-top: 120px;
            padding-bottom: 55px;
        }

        h1 {
            font-size: 56px;

            @media (max-width: 580px) {
                font-size: 36px;
            }
        }
    }

    &__menu {
        background-color: $brand-mid;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__filter {
        display: none;
        padding-bottom: 10px;

        @media (min-width: 992px) {
            display: flex;
            align-items: center;
        }

        button {
            padding: 0;
            background-color: transparent;
            border: none;
            margin-left: 20px;
            margin-right: 20px;
            line-height: 42px;
            font-size: 16px;
            font-weight: 600;
            color: $heading-color;
            transition: all 0.3s;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -10px;
                height: 0;
                background-color: $c-white;
                transition: all 0.3s;
                z-index: 3;
            }

            &:hover,
            &.is-checked {
                color: $c-white;

                &:before {
                    height: 3px;
                }
            }
        }

        &-select {
            @media (min-width: 992px) {
                display: none;
            }
        }
    }

    // SELECT
    &__select {
        padding-left: 20px;
        padding-right: 20px;
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 20px;
        max-width: 350px;

        @media (min-width: 576px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media (min-width: 992px) {
            padding-left: 0;
            padding-right: 0;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }

        .select2-container {
            width: 100%;

            @media (min-width: 992px) {
                width: 186px;
            }

            .select2-selection--single {
                border: none !important;
                background: transparent;
                border-radius: 6px;
                height: 42px;
                box-shadow: inset 0 0 0 2px rgba($heading-color, 0.5);
                transition: all 0.3s;

                @media (min-width: 992px) {
                    background: rgba($heading-color, 0.1);
                    box-shadow: none;
                }
            }
        }

        .select2-container--default.select2-container--open
            .select2-selection--single {
            background: $c-white;
            box-shadow: none;

            .select2-selection__placeholder,
            .select2-selection__rendered {
                color: rgba($heading-color, 0.5);
            }
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__rendered {
            line-height: 42px;
            padding-left: 20px;
            color: $heading-color;
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__placeholder {
            font-size: 16px;
            color: $heading-color;
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__arrow {
            height: 100%;
            top: 0;
            right: 22px;
            width: 18px;
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__arrow
            b {
            border: none;
            margin: 0;
            transform: translate(-50%, -50%);
            width: 18px;
            height: 9px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            background-image: url(../img/res-arrow.svg);
            transition: all 0.3s;
        }

        .select2-container--default.select2-container--open
            .select2-selection--single
            .select2-selection__arrow
            b {
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }

    &__items {
        max-width: 705px;
        width: calc(100% - 40px);
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 40px;

        @media (min-width: 768px) {
            padding-top: 50px;
            padding-bottom: 90px;
        }
    }

    &__item {
        $it: &;

        width: 100%;
        padding: 20px 0;
        // border-bottom: 1px dashed $heading-color;
        position: relative;
        color: $heading-color;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-image: url(../img/faq-line.svg);
        }

        @media (min-width: 768px) {
            padding: 40px 0;
        }

        &:last-child {
            border-bottom: none;
        }

        &.active {
            #{$it}-header {
                span {
                    &:after {
                        transform: translate(-50%, -50%) rotate(0);
                    }
                }
            }
        }

        &-header {
            position: relative;
            cursor: pointer;
            font-size: 17px;
            font-weight: 600;
            line-height: 1.5;
            padding-right: 30px;

            @media (min-width: 768px) {
                font-size: 20px;
                line-height: 32px;
            }

            span {
                position: absolute;
                top: 9px;
                right: 5px;
                width: 18px;
                height: 18px;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 3px;
                    background-color: $brand-mid;
                    transition: all 0.3s;
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(90deg);
                }
            }
        }

        &-content {
            display: none;
            font-size: 16px;
            line-height: 1.55;
            padding-top: 20px;

            @media (min-width: 768px) {
                font-size: 18px;
            }

            a {
                color: $c-brand;
                text-decoration: underline;

                &:hover {
                    color: $heading-color;
                }
            }

            p {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            .arve {
                max-width: 100% !important;
            }
            iframe {
                width: 100%;
                height: 200px;

                @media (min-width: 768px) {
                    height: 400px;
                }
            }
        }
    }
}
