.legal {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: $brand-light;
    // background-image: linear-gradient(to bottom, #f4f7f7 0%, #5fd3d4 100%);

    @include respond-to(sm) {
        padding-top: 170px;
        padding-bottom: 170px;
    }
    &.homepage-quote {
        padding-top: 40px;
        padding-bottom: 40px;
        @include respond-to(sm) {
            padding-bottom: 100px;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 2.4rem;
    }
    h1 {
        @include respond-to(sm) {
            font-size: 5.6rem;
            line-height: 7rem;
        }
    }
    h2 {
        @include respond-to(sm) {
            font-size: 3.6rem;
            line-height: 1;
        }
    }
    span {
        margin-bottom: 40px;
        display: inline-block;
        font-size: 2.4rem;
    }

    h3 {
        margin-top: 50px;
        font-size: 2rem;
        line-height: 1.6;
    }

    h4 {
        font-size: 1.8rem;
        line-height: 1.6;
        font-weight: bold;
    }

    h5,
    h6 {
        font-size: 1.6rem;
        line-height: 1.6;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: bold;
    }

    h6 {
        opacity: 0.5;
    }

    ol,
    ul {
        margin-bottom: 35px;
        padding-left: 20px;
    }
    ul {
        list-style: disc;
    }

    li {
        color: $heading-color;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    p {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }
    a {
        color: $c-brand;
        text-decoration: underline;
        &:hover {
            color: $heading-color;
        }
    }
    .resource-asset {
        font-size: 24px;
        @include respond-to(sm) {
            font-size: 32px;
        }
    }
    .container > * {
        @include when-inside('.is-observer') {
            opacity: 0;
            @include transition-reveal;

            &.is-visible {
                opacity: 1;
            }
        }
    }
}
