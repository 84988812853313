// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
}

body {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
}

main {
    position: relative;
    overflow: hidden;
    background-color: $brand-mid;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;

    margin: 0;
    padding: 0;
}

img {
    display: block;
    height: auto;
    /* Make sure images are scaled correctly. */
    max-width: 100%;
    /* Adhere to container width. */
}

/**
 * Basic styles for links
 */
a {
    text-decoration: none;
    @include transition;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

button {
    color: inherit;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

blockquote {
    h2 {
        margin-bottom: 24px;
        font-size: 24px;
        line-height: 1;
        @include respond-to(sm) {
            margin-bottom: 28px;
            font-size: 36px;
        }
    }
    p {
        font-size: 18px;
        line-height: 28.8px;
        color: $heading-color;
        opacity: 0.75;
    }
}
